import React from 'react';

const TestimonialsCard = ({
  id,
  cardData,
  authorsText,
  background,
  fillQuotes,
}) => {
  return (
    <div
      key={id}
      className="testimonials-card"
      style={{ backgroundColor: background }}
    >
      <svg
        width="32"
        height="20"
        viewBox="0 0 32 20"
        fill={fillQuotes}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.7985 20C23.8113 20 22.8627 19.8183 21.9528 19.4548C21.0623 19.0722 20.2783 18.5366 19.6007 17.8479C18.9425 17.1593 18.4102 16.3367 18.0036 15.3802C17.6165 14.4046 17.4229 13.3238 17.4229 12.1377C17.4229 10.5882 17.6358 9.19177 18.0617 7.94835C18.4876 6.6858 19.0684 5.55715 19.804 4.56241C20.559 3.54854 21.4495 2.66858 22.4755 1.92253C23.5015 1.17647 24.6146 0.535629 25.8149 0L27.7604 1.80775C26.4634 2.5538 25.4567 3.36681 24.7405 4.24677C24.0436 5.12673 23.5596 6.1693 23.2886 7.37446C23.5596 7.29794 23.85 7.23099 24.1597 7.1736C24.4694 7.11621 24.8469 7.08752 25.2922 7.08752C26.2988 7.08752 27.2087 7.23099 28.0218 7.51793C28.8542 7.80488 29.5608 8.22573 30.1416 8.78049C30.7417 9.31612 31.1966 9.98565 31.5064 10.7891C31.8354 11.5925 32 12.5108 32 13.5438C32 14.4429 31.8354 15.2846 31.5064 16.0689C31.1966 16.8532 30.732 17.5418 30.1125 18.1349C29.5124 18.7088 28.7574 19.1679 27.8475 19.5122C26.957 19.8374 25.9407 20 24.7985 20ZM7.37568 20C6.38838 20 5.43981 19.8183 4.52995 19.4548C3.63944 19.0722 2.85541 18.5366 2.17786 17.8479C1.51966 17.1593 0.987296 16.3367 0.580762 15.3802C0.193587 14.4046 0 13.3238 0 12.1377C0 10.5882 0.212946 9.19177 0.638838 7.94835C1.06473 6.6858 1.64549 5.55715 2.38113 4.56241C3.13612 3.54854 4.02662 2.66858 5.05263 1.92253C6.07864 1.17647 7.19177 0.535629 8.39201 0L10.3376 1.80775C9.04053 2.5538 8.03388 3.36681 7.3176 4.24677C6.62069 5.12673 6.13672 6.1693 5.8657 7.37446C6.13672 7.29794 6.4271 7.23099 6.73684 7.1736C7.04658 7.11621 7.42408 7.08752 7.86933 7.08752C8.87598 7.08752 9.78584 7.23099 10.5989 7.51793C11.4313 7.80488 12.1379 8.22573 12.7187 8.78049C13.3188 9.31612 13.7737 9.98565 14.0835 10.7891C14.4126 11.5925 14.5771 12.5108 14.5771 13.5438C14.5771 14.4429 14.4126 15.2846 14.0835 16.0689C13.7737 16.8532 13.3091 17.5418 12.6897 18.1349C12.0895 18.7088 11.3345 19.1679 10.4247 19.5122C9.53418 19.8374 8.51785 20 7.37568 20Z"
          fill="inherit"
        />
      </svg>
      <div className="testimonials-card__text">
        {authorsText[id].map((text, textId) => (
          <div key={textId}>{text}</div>
        ))}
      </div>

      <div className="testimonials-card__author">
        <span className="testimonials-card__author-title">
          <svg
            width="34"
            height="3"
            viewBox="0 0 34 3"
            fill="rgb(3, 164, 115)"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="1.5"
              x2="33"
              y2="1.5"
              stroke={fillQuotes}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
          {cardData.author}
        </span>

        <span className="testimonials-card__author-description">
          {cardData.role}
        </span>
      </div>
    </div>
  );
};
export default TestimonialsCard;
