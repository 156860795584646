import React from 'react';
import { replaceSubDomain, windowSizeChecker } from '../../utils';
import SliderUniversal from '../Slider';

const Partners = ({ partnersList }) => {
  const isMobile = windowSizeChecker.isMobile();
  const isSmallTablet = windowSizeChecker.isSmallTablet()
  return (
    <section className="section partners-container">
      <SliderUniversal numberOfSlides={isMobile ? 2 : isSmallTablet ? 3 : 4}>
        {partnersList.map(({icon, id}) => (
          <div 
            key={id}
            className="partners"
          >
            <img
              src={replaceSubDomain(icon.desktop)}
              alt=""              
              className="partner"
            />
          </div>
        ))}
      </SliderUniversal>
    </section>
  );
};

export default Partners;
