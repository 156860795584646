const colorBackground = ['#EBF8F4','#FEF5EE','#FEF6FB','#ECF4F8','#FDFEEB']
const colorQuotes = ['#03A473','#F38327','#DF56A8','#003D57','#CBCE22']

export function colorManager (index) {
  let idx = index
  const colorIndex = colorBackground.length % index
  if (idx <= colorBackground.length) {
    return {
      colorQuotes: colorQuotes[idx],
      colorBackground: colorBackground[idx]
    }
  } else {
    return {
      colorQuotes: colorQuotes[colorIndex],
      colorBackground: colorBackground[colorIndex]
    }
  }
}
