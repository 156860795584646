import React from "react";
import Slider from 'react-slick';

const SliderUniversal = ({children, numberOfSlides, autoScroll, dotsPagination}) => {
  const settingsForSliderUniversal = {
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    useCSS: true,
    slidesToShow: numberOfSlides || 2,
    dots: dotsPagination || false,
    autoplay: autoScroll || false,
    autoplaySpeed: 6000,
  }
  return (
      <Slider {...settingsForSliderUniversal}>
        {children}
      </Slider>
  )
}
export default SliderUniversal
