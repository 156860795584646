import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsForm from '../Forms/ContactUsForm';
import { Modal } from '../ModalForm';
import { Thanks } from '../Thanks';

const ContactUsAndThanks = ({ show = false, onHide = () => {} }) => {
  const { t } = useTranslation();
  const [isContactUsModalShow, setIsContactUsModalShow] = useState(false);
  const [isThanksModalShow, setIsThanksModalShow] = useState(false);

  useEffect(() => {
    setIsContactUsModalShow(show);
  }, [show]);

  const hideContactUsModal = () => {
    setIsContactUsModalShow(false);
    onHide();
  };
  const openThanksModal = () => {
    setIsThanksModalShow(true);
  };

  const hideThanksModal = () => {
    setIsThanksModalShow(false);
  };
  const onSubmitContactUsHendler = () => {
    hideContactUsModal();
    openThanksModal();
  };
  return (
    <>
      <Modal
        show={isContactUsModalShow}
        onHide={hideContactUsModal}
        title={t('contactUsModal.title')}
        children={
          <ContactUsForm
            onHide={hideContactUsModal}
            onSubmit={onSubmitContactUsHendler}
          />
        }
      />
      <Modal
        show={isThanksModalShow}
        onHide={hideThanksModal}
        children={<Thanks hideThanksModal={hideThanksModal}/>}
      />
    </>
  );
};
export default ContactUsAndThanks;
