import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsAndThanks from '../components/CombineModals/ContactUsAndThanks';
import Layout from '../components/Layout';
import Partners from '../components/Partners';
import SEO from '../components/Seo';
import TestimonialsCard from '../components/TestomonialsCard';
import { colorManager } from '../components/TestomonialsCard/colorManagerForTestimonials/colorManager';
import TitleImgBlock from '../components/TitleImgBlock';
import { createMarkup } from '../helpers/createMarkup';

const Testimonials = ({ pageContext: { data } }) => {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = () => {
    setIsOpenModal(true);
  };
  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  const text = [];

  data.testimonials_content.map((item) => {
    text.push(item.message.split(['\n']));
  });

  return (
    <Layout>
      <SEO title="Testimonials" />

      <div className="page-services page-testimonials page-default">
        {data.header.id && <TitleImgBlock title={data.header.title} />}
      </div>
      <div className="container" id="comment_container">
        <div className="testimonials-wrap">
          {data.testimonials_content?.map((card, idx) => {
            const { colorBackground, colorQuotes } = colorManager(idx);
            return (
              <TestimonialsCard
                key={card.id}
                authorsText={text}
                id={idx}
                cardData={card}
                background={colorBackground}
                fillQuotes={colorQuotes}
              />
            );
          })}
        </div>
      </div>

      <section className="section text-center testimonials-title">
        <div className="container">
          <div
            className="h1"
            dangerouslySetInnerHTML={createMarkup(
              t('homePage.contactUs.title')
            )}
          />
          <div className="pt-3">
            <button
              onClick={openModalHandler}
              className="btn btn-lg btn-primary rounded-pill mt-1"
            >
              {t('homePage.contactUs.btn')}
            </button>
          </div>
        </div>
      </section>

      <Partners partnersList={data.partner_list} />

      <ContactUsAndThanks show={isOpenModal} onHide={closeModalHandler} />
    </Layout>
  );
};

export default Testimonials;
