import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Background, Parallax } from 'react-parallax';
import { createMarkup } from '../../helpers/createMarkup';

const TitleImgBlock = ({ title, image }) => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div>
      <div className="container">
        <div className="">
          <div className="scroll-container">
            <h1
              className="pageTitle"
              data-aos={'fade-up'}
              dangerouslySetInnerHTML={createMarkup(t(title))}
            />
          </div>
        </div>
      </div>

      {image && (
        <div className="main-baner">
          <div className="sub-container">
            <Parallax
              data-aos={'zoom-in'}
              data-aos-anchor-placement={'top-top'}
              strength={200}
              className="paralax-img"
              blur={0}
            >
              <Background>
                <div className="animate-hero-image">
                  <img src={image} alt="fill murray" />
                </div>
              </Background>
            </Parallax>
          </div>
        </div>
      )}
      <div id="after-main-baner-section" />
    </div>
  );
};

export default TitleImgBlock;
